<template>
  <v-main>
   
     <v-row class="ma-5">
       <v-col cols="6">
           <v-card color="success" dark>
              <v-card-text class="text-center">
               <span class="text-h4">{{$money(account.balance)}}</span>
               <br/>
                  Current Load
              </v-card-text>
             </v-card>
          </v-col>
         <v-col cols="6">
              <v-card color="secondary" dark>
               <v-card-text class="text-center">
               <span class="text-h4">{{$money(account.comm_amount)}}</span>
               <br/>
                  Current Commission
              </v-card-text>
             </v-card>
            
         </v-col>
        <v-col cols="12">
         <v-card dense>
         <v-toolbar dense dark color="secondary">
            Today's Summary
             <v-spacer/>
                 <v-spacer/>
            As of : {{ this.$moment().format('MMM DD, YYYY')}}
         </v-toolbar>
         <v-card-text>
              <v-progress-linear
                 v-if="loading"
                 indeterminate
                 color="teal"
                 ></v-progress-linear>
            
             <table class="print_table" id="over-table">
                 <tr>
                   
                     <th class="text-left">GAME</th>
                    
                     <th class="text-left">DRAW DATE</th>
                     <th class="text-right">GROSS</th>
                     <th class="text-right">AMOUNT HITS</th>
                 </tr>
                  <tr v-for="(item,index) in items" :key="index" >
                      <!-- <td class="border_bot">{{item.draw_id}} </td> -->
                     <td class="border_bot">{{item.game}} </td>
                      <td class="border_bot">{{item.draw_date}} </td>
                       <td class="text-right border_bot">{{$money(item.total)}}</td>
                       <td class="text-right border_bot">{{$money(item.win_amount)}}</td>
                 </tr>
                 <tr>
                     
                     <th  class="text-right" style="background: #efefef;" colspan="2"></th>
                     <th class="text-right"  style="background: #efefef;">TOTAL GROSS : {{$money(GROSS)}}</th>
                     <th class="text-right"  style="background: #efefef;">TOTAL WIN : {{$money(TOTALHITS)}}</th>
                 </tr>
                
             </table>
         </v-card-text>     
     </v-card>
        </v-col>
         
     </v-row>
      <v-row class="ma-5">
        
         <v-col cols="6">
           <v-card>
               <v-toolbar dense>Latest Bets</v-toolbar>
               <v-card-text>
                     <table class="va-table print_table3 mt-1  text-left" cellspacing="0">
                      <!-- <tr>
                        <th  style="background: #eaeaea; padding: 4px">TICKET</th>
                        <th  style="background: #eaeaea; padding: 4px" class="text-right">AMOUNT</th>
                      </tr> -->
                       <tr v-for="(item, i) in tickets" :key="i">
                           <td style="padding: 3px;"> {{item.account_name}}
                           <br/>{{item.transaction_date | moment("YYYY-MM-DD HH:mm:ss A")}}
                           </td>
                           <td style="padding: 3px;">{{GAMENAME(item.game)}}</td>
                           <td style="padding: 3px;" class="text-right">{{$money(item.amount)}}</td>
                       </tr>
                   </table>
               </v-card-text>
           </v-card>
         </v-col>
            <v-col cols="6">
           <v-card>
               <v-toolbar dense>DRAW RESULTS
                 
                 <v-spacer/>
 
                 <span style="width: 140px;">
                 <v-autocomplete 
                   outlined
                   v-model="game"
                   dense
                   hide-details
                   label="Filter"
                   :items="ACTIVEGAMES(user.game_comm)"
                 />
                 </span>
               </v-toolbar>
               <v-card-text>
                 <div style="height: 620px; overflow-y: scroll;">
                    <table class="print_table" >
                       <tr>
                           <th class="text-left">DRAW</th>
                           <th class="text-right">RESULT</th>
                       </tr>
                       <tr v-for="(item, index) in RESULTS" :key="index">
                           <td> {{GAMENAME(item.game)}} <br/>{{$moment(item.date).format('YY/MM/DD') + ' ' + item.time}} </td>
                         
                           <td class="text-right">{{ViewResult(GAMENAME(item.game), item.combination)}}</td>
                       </tr>
                   </table>
                 </div>
               </v-card-text>
           </v-card>
         </v-col>
      </v-row>
   </v-main>
 </template>
 <script>
 import { API_MIXINS } from '@/mixins/api_mixins.js'
 import {
   mapMutations
 } from 'vuex'
   export default {
     mixins: [API_MIXINS],
     data: () => ({
       CHECKINTERVAL: null,
       accounts:[],
       account:{},
       game:"ALL",
     //  activegames:[],
       tickets:[],
       items:[],
        loading: false,
       sales:[]
     }),
     created() {
       this.PING()
       console.log("created")
       this.userDetails()
       //this.activegames = this.ACTIVEGAMES(this.user.game_comm)
     },
      mounted(){
          this.setDrawer(false)
        this.setLoggedIn(true)
         if (this.user == null) {
           this.$router.push('/')
         }else {
           this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
          this.GET_ALL_ACCOUNTS(this.$IsNum(this.user.id),this.user.role)
         // this.userDetails()
          this.getTickets()
          this.getTransactions()
          this.GET_RESULTS(this.user.game_comm)
          console.log("mounted")
         }
         
     },
     beforeDestroy(){
        if(this.CHECKINTERVAL != null) { clearInterval(this.CHECKINTERVAL)}
        return true
     },
     computed:{
       balance(){
         return  this.$store.state.balance.balance
       },
       
       isOnline(){
         return this.$store.state.is_online
       },
       user() {
         if(localStorage.user){
             return JSON.parse(localStorage.user)
         }
         return null
       },
       
       COORDINATORS(){
         var acct = this.$store.state.users.reduce((res, item)=>{
             if(item.role =='Coordinator') {
               res.push(item)
             }
             return res
         }, [] )
         return acct
       },
       AGENTS(){
         var acct = this.$store.state.users.reduce((res, item)=>{
             if(item.role =='Agent') {
               res.push(item)
             }
             return res
         }, [] )
         return acct
       },
        RESULTS(){
         var items = []
           if(this.game == "ALL") {
             return  this.$store.state.results
           } else {
              items = this.$store.state.results.reduce((res,item)=>{
               if(item.game == this.game) {
                 res.push(item)
               }
               return res
             },[])
           }
          return items
        
       },
        GROSS() {
                 var total = this.items.reduce((res,item)=>res += this.$IsNum(item.total),0)
                 return total
         },
        
          TOTALHITS() {
             var total = this.items.reduce((res,item)=>res += this.$IsNum(item.win_amount),0)
                 return total
         }
       
     },
     methods: {
       ...mapMutations(['setDrawer', 'setAlertColor', 'setAlertText', 'setLoggedIn', 'setOverlayLoading', 'setOverlayLoadingText', 'setOpenMachine','setWatchDraw', 'setLiveDrawStatus']),
       ViewResult(game, combination){
         var combi = combination.split(' - ')
         if(game == '3D' || game == '3D-STL-ILOILO-CITY') {
           return combi[0] + ' - ' + combi[1] + ' - ' + combi[2]
         } else if(game == '4D') {
           return combi[0] + ' - ' + combi[1] + ' - ' + combi[2] + ' - ' + combi[3]
         } 
         else {
            return combi[0] + ' - ' + combi[1] 
         }
       },
       activateGame() {
          return false
       },
       userDetails(){
           this.$http.post("user/details",{id: this.user.id}).then(response => {
               if(response.data.status) {
                  this.account = response.data.account
               } 
           }).catch(e => {
             console.log(e.data)
           })
     },
        getTickets(){
          this.$http.post("sa/tickets",{coor_id: this.$IsNum(this.user.id)}).then(response => {
             response.data.items != null?this.tickets=response.data.items:this.tickets = []
              response.data.sales != null?this.sales=response.data.sales:this.sales = []
              console.log(response.data)
           }).catch(e => {
             console.log(e.data)
           })
       },
       getTransactions(){
             this.loading = true
             this.items =[]
             var param = {}
             param.from_date = this.$moment().format('YYYY-MM-DD')
             param.to_date =this.$moment().format('YYYY-MM-DD')
             param.method = "per_game_gen_coordinator_summary"
              this.$http.post("report/transactions",param).then(response => {
                 response.data.items !=null?this.items = response.data.items:this.items =[]
                // console.log(response.data)
                 this.loading = false
           }).catch(e => {
             console.log(e.data)
           })
         }
     },
   }
 </script>